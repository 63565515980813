<script>
import { ValidationObserver } from 'vee-validate'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useForm } from '/~/composables/base/use-form'
import FlyCard from './fly-card.vue'

export default {
  name: 'profile-points-partner-add',
  components: {
    FlyCard,
    BaseInput,
    BaseButton,
    BaseAsidePage,
    ValidationObserver,
  },
  setup() {
    const { validationObserverRef } = useForm()

    return {
      validationObserverRef,
    }
  },
  data() {
    return {
      membershipIdMask: {
        mask: '0000000000',
      },
      form: {
        firstName: '',
        lastName: '',
        membershipId: '',
      },
      submitting: false,
      backendErrors: {},
    }
  },
  computed: {
    disabled() {
      return (
        this.submitting ||
        !this.form.firstName ||
        !this.form.lastName ||
        !this.form.membershipId
      )
    },
    name() {
      return `${this.form.firstName} ${this.form.lastName}`.trim() || 'Account'
    },
    membershipId() {
      return this.form.membershipId || '0000000000'
    },
  },
  methods: {
    // ...mapActions('fly', {
    //   addAccount: 'ADD_ACCOUNT',
    // }),
    async onSubmit() {
      this.submitting = true
      this.backendErrors = {}

      // const [error] = await this.addAccount(this.form)

      // if (error) {
      this.backendErrors.membershipId =
        'Unable to validate your Velocity Frequent Flyer details'
      // }

      this.submitting = false
    },
  },
}
</script>

<template>
  <base-aside-page title="Velocity Frequent Flyer">
    <div class="text-center">
      Simply complete the form in order to add your Velocity Frequent Flyer
      Account in to your Airline Partners Section
    </div>
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="flex w-full flex-1 flex-col"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <div class="mx-auto w-72 max-w-full p-5">
          <fly-card
            :name="name"
            :full-width="true"
            :membership-id="membershipId"
          />
        </div>

        <div>
          <base-input
            v-model="form.firstName"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :disabled="submitting"
            label="First Name"
            required
            name="firstName"
          />
          <base-input
            v-model="form.lastName"
            :validation="{
              rules: 'required',
              name: 'Last Name',
            }"
            :disabled="submitting"
            label="Last Name"
            required
            name="lastName"
          />
          <base-input
            v-model="form.membershipId"
            :validation="{
              rules: 'required',
              name: 'Velocity Frequent Flyer Number',
            }"
            :error="backendErrors.membershipId"
            :mask="membershipIdMask"
            :disabled="submitting"
            label="Velocity Frequent Flyer Number"
            required
            name="membershipId"
          />
        </div>

        <base-button class="mt-auto" type="submit" :disabled="disabled">
          Save
        </base-button>
      </form>
    </validation-observer>
  </base-aside-page>
</template>
